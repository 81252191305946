import { m } from 'framer-motion';
// @mui
import { Container, Typography } from '@mui/material';
// hooks
import useAuth from '@common/hooks/useAuth';
// components
import { MotionContainer, varBounce } from '@common/components/animate';
// assets
import { ForbiddenIllustration } from '@common/assets';
import { BlacklistedAttributeGuardProps } from '@common/@types/guards';

// ----------------------------------------------------------------------

export default function BlacklistedAttributeGuard({
  hasContent,
  blacklistFunction,
  children,
}: BlacklistedAttributeGuardProps) {
  const { user } = useAuth();
  if (user === null) {
    throw new Error('User is not authenticated');
  }

  if (typeof blacklistFunction !== 'undefined' && blacklistFunction && blacklistFunction(user)) {
    return (
      <>
        {hasContent ? (
          <Container component={MotionContainer} sx={{ textAlign: 'center' }}>
            <m.div variants={varBounce().in}>
              <Typography variant="h3" paragraph>
                Permission Denied
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <Typography sx={{ color: 'text.secondary' }}>
                You do not have permission to access this page
              </Typography>
            </m.div>

            <m.div variants={varBounce().in}>
              <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
            </m.div>
          </Container>
        ) : null}
      </>
    );
  }

  return <>{children}</>;
}
