import { ReactNode } from 'react';
import PageNoMobile from '../pages/no-mobile';

function isMobile() {
  return window.innerWidth <= 750;
}

export default function MobileGuard({ children }: Props) {
  if (isMobile()) {
    return <PageNoMobile />;
  }
  return <>{children}</>;
}

type Props = {
  children: ReactNode;
};
