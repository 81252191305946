import { ReactNode, useEffect, useState } from 'react';
// next
import { useRouter } from 'next/router';
// hooks
import useAuth from '@common/hooks/useAuth';
import Login from '../pages/auth/login';
// components
import LoadingScreen from '@common/components/LoadingScreen';
import { Role } from '@common/@types/auth';
import Page403 from '../pages/403';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { user, isAuthenticated, isInitialized } = useAuth();

  const { pathname, push } = useRouter();

  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    if (requestedLocation && pathname !== requestedLocation) {
      push(requestedLocation);
    }
    if (isAuthenticated) {
      setRequestedLocation(null);
    }
    // TODO: if role is not admin or guide, add page to point to Dash
  }, [isAuthenticated, pathname, push, requestedLocation]);

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  const role = user?.alphaAuthAttributes?.role;
  if (!role || ![Role.ADMIN, Role.GUIDE].includes(role)) {
    return <Page403 />;
  }

  return <>{children}</>;
}
