// i18n
import '@common/locales/i18n';

// scroll bar
import 'simplebar/dist/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'react-lazy-load-image-component/src/effects/black-and-white.css';

import cookie from 'cookie';
import { ReactElement, ReactNode } from 'react';
// next
import { NextPage } from 'next';
import Head from 'next/head';
import App, { AppContext, AppProps } from 'next/app';
//
import { Provider as ReduxProvider } from 'react-redux';
// @mui
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// redux
import { store } from '@common/redux/store';
// utils
import { getSettings } from '@common/utils/getSettings';
// contexts
import { LayoutProvider } from '@common/contexts/LayoutContext';
import { SettingsProvider } from '@common/contexts/SettingsContext';
import { CollapseDrawerProvider } from '@common/contexts/CollapseDrawerContext';
import { DashboardSelectionProvider } from '@common/contexts/DashboardSelectionContext'; // LayoutProvider things

import GuestGuard from '../guards/GuestGuard';
import MobileGuard from '../guards/MobileGuard';
import AuthGuard from '../guards/AuthGuard';
import BlacklistedAttributeGuard from '../guards/BlacklistedAttributeGuard';
import { MENU_OPTIONS } from '../routes/AccountPopoverPaths';
import navConfig from '../routes/NavConfig';
import { Logo, logoHeight } from '../sections/Logo';
// theme
import ThemeProvider from '@common/theme';
// components
import ThemeSettings from '@common/components/settings';
import { SettingsValueProps } from '@common/components/settings/type';
import ProgressBar from '@common/components/ProgressBar';
import NotistackProvider from '@common/components/NotistackProvider';
import MotionLazyContainer from '@common/components/animate/MotionLazyContainer';

import { AuthProvider } from '@common/contexts/JWTContextAmplify';
import HelpDeskIcon from '@common/components/external-scripts/HelpDeskIcon';
import GoogleAnalytics from '@common/components/external-scripts/GoogleAnalytics';
import { QueryClient, QueryClientProvider } from 'react-query';

import { UserFlagContextProvider } from '@common/contexts/UserFlagContext';

import InitializeReduxStore from '@common/redux/InitializeReduxStore';
import { LicenseInfo } from '@mui/x-license-pro';
import { PageViewTracker } from '@common/utils/pageViewTracker';
import dayjs from '@common/@types/dayjs-custom';
import { ModalUsageProvider } from '@common/contexts/ModalUsageContext';

// ----------------------------------------------------------------------
dayjs();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  settings: SettingsValueProps;
  Component: NextPageWithLayout;
}

const MyApp = (props: MyAppProps) => {
  const defaultSettings: SettingsValueProps = {
    themeMode: 'light',
    themeLayout: 'horizontal',
    themeStretch: false,
    themeContrast: 'default',
    themeDirection: 'ltr',
    themeColorPresets: 'default',
  };
  const { Component, pageProps, settings } = props;

  const getLayout = Component.getLayout ?? ((page) => page);
  const queryClient = new QueryClient();
  LicenseInfo.setLicenseKey(
    'e091347a2d927c5d94a00815f54ba32eTz02Mjk4MixFPTE3MTE2NTIwMzA1NjIsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
  );

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <AuthProvider>
        <LayoutProvider
          GuestGuard={GuestGuard}
          MobileGuard={MobileGuard}
          AuthGuard={AuthGuard}
          PageGuard={(input) => <>{input.children}</>}
          BlacklistedAttributeGuard={BlacklistedAttributeGuard}
          accountPopoverOptions={MENU_OPTIONS}
          navConfig={navConfig}
          Logo={Logo}
          logoHeight={logoHeight}
        >
          <ReduxProvider store={store}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <CollapseDrawerProvider>
                <DashboardSelectionProvider>
                  <UserFlagContextProvider>
                    <SettingsProvider defaultSettings={settings || defaultSettings}>
                      <MotionLazyContainer>
                        <QueryClientProvider client={queryClient}>
                          <ThemeProvider>
                            <ThemeSettings>
                              <NotistackProvider>
                                <InitializeReduxStore>
                                  <ModalUsageProvider>
                                    <ProgressBar />
                                    <HelpDeskIcon />
                                    <GoogleAnalytics />
                                    <PageViewTracker />
                                    {getLayout(<Component {...pageProps} />)}
                                  </ModalUsageProvider>
                                </InitializeReduxStore>
                              </NotistackProvider>
                            </ThemeSettings>
                          </ThemeProvider>
                        </QueryClientProvider>
                      </MotionLazyContainer>
                    </SettingsProvider>
                  </UserFlagContextProvider>
                </DashboardSelectionProvider>
              </CollapseDrawerProvider>
            </LocalizationProvider>
          </ReduxProvider>
        </LayoutProvider>
      </AuthProvider>
      {/* <Analytics /> */}
    </>
  );
};

export default MyApp;

// ----------------------------------------------------------------------

MyApp.getInitialProps = async (context: AppContext) => {
  const appProps = await App.getInitialProps(context);

  const cookies = cookie.parse(
    context.ctx.req ? context.ctx.req.headers.cookie || '' : document.cookie
  );

  const settings = getSettings(cookies);

  return {
    ...appProps,
    settings,
  };
};
