// routes
import {
  PATH_COACHING,
  PATH_GUIDE,
  PATH_MANAGEMENT,
  PATH_RECOMMENDATIONS,
  PATH_STUDYREEL,
  PATH_TESTING,
} from './paths';
// components
import {
  CameraRoll,
  Edit,
  Inventory,
  ListAlt,
  LocalLibrary,
  Person,
  RateReview,
  School,
  Sports,
} from '@mui/icons-material';

import { AuthUser, Role } from '@common/@types/auth';

export type BlacklistFunction = (user: AuthUser) => boolean;

export function blockUserNotInRoles(...roles: Role[]): BlacklistFunction {
  return (user: AuthUser) => roles.every((role) => user?.alphaAuthAttributes?.role !== role);
}

// @ts-ignore
const navConfig = [
  {
    subheader: 'Guide Tools',
    items: [
      {
        title: '2hr Overrides',
        path: PATH_GUIDE.override.root,
        icon: <Edit />,
      },
      {
        title: 'Test Coaching',
        path: PATH_GUIDE.testCoaching.root,
        icon: <Sports />,
      },
    ],
  },
  {
    subheader: 'StudyReel',
    items: [
      {
        title: 'StudyReel',
        path: PATH_STUDYREEL.root,
        icon: <CameraRoll />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
      {
        title: 'Clip Review',
        path: PATH_STUDYREEL.clipReview.root,
        icon: <RateReview />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
      {
        title: 'Anti Pattern Inventory',
        path: PATH_STUDYREEL.ap_inventory,
        icon: <RateReview />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
    ],
  },
  {
    subheader: 'testing',
    items: [
      {
        title: 'bulk assign',
        path: PATH_TESTING.bulkTestAssign,
        icon: <Inventory />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
      {
        title: 'test assignment',
        path: PATH_TESTING.gradeClearedTestAssignment,
        icon: <Inventory />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
      {
        title: 'next best test',
        path: PATH_TESTING.nextBestTest,
        icon: <Inventory />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
    ],
  },
  {
    subheader: 'recommendations',
    items: [
      {
        title: 'recommendations',
        path: PATH_RECOMMENDATIONS.root,
        icon: <School />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
      {
        title: 'skills override',
        path: PATH_RECOMMENDATIONS.skillsOverride,
        icon: <School />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
    ],
  },
  {
    subheader: 'coaching',
    items: [
      {
        title: 'coaching',
        path: PATH_COACHING.root,
        icon: <Person />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
    ],
  },
  {
    subheader: 'management',
    items: [
      {
        title: 'student app roster',
        path: PATH_MANAGEMENT.studentAppRoster,
        icon: <LocalLibrary />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
      {
        title: 'skill plans',
        path: PATH_MANAGEMENT.skillPlan,
        icon: <ListAlt />,
        blacklistFunction: (user: AuthUser) => user?.alphaAuthAttributes?.role !== Role.ADMIN,
      },
    ],
  },
];

export default navConfig;
