var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"6f4bb1869c024555ac3d11a909386db2cc1b5252"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

const getEnv = () => {
  const environment = process.env.NODE_ENV;
  if (environment) {
    if (environment.includes('prod')) {
      return 'production';
    } else if (environment.includes('development')) {
      return 'development';
    }
  }
  return 'production';
};

const canTrack = () => {
  return process.env.NEXT_PUBLIC_MIXPANEL_ENV_TO_TRACK === process.env.NEXT_PUBLIC_ENV;
};

if (canTrack()) {
  console.log('Sentry initialized!');
  Sentry.init({
    dsn:
      SENTRY_DSN ||
      'https://e747f660b6c74af387579c3319cd0f2c@o924797.ingest.sentry.io/4505607679311872',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    environment: getEnv(),
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}
