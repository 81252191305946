function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_HOME = '/home';
const ROOTS_STUDYREEL = '/studyreel';
const ROOTS_TESTING = '/testing';
const ROOTS_RECOMMENDATIONS = '/recommendations';
const ROOTS_COACHING = '/coaching';
const ROOTS_MANAGEMENT = '/management';
const ROOTS_GUIDE = '/guide';

export const PATH_HOME = {
  root: ROOTS_HOME,
};

const PATH_CLIPREVIEW = path(ROOTS_STUDYREEL, '/clip-review');
export const PATH_STUDYREEL = {
  root: ROOTS_STUDYREEL,
  subpage: path(ROOTS_STUDYREEL, '/subpage'),
  ap_inventory: path(ROOTS_STUDYREEL, '/ap-inventory'),
  clipReview: {
    root: PATH_CLIPREVIEW,
    create: path(PATH_CLIPREVIEW, '/create'),
    edit: path(PATH_CLIPREVIEW, '/edit'),
    view: path(PATH_CLIPREVIEW, '/view'),
  },
};

export const PATH_TESTING = {
  root: ROOTS_TESTING,
  bulkTestAssign: path(ROOTS_TESTING, '/bulk-assign'),
  gradeClearedTestAssignment: path(ROOTS_TESTING, '/test-assignment'),
  nextBestTest: path(ROOTS_TESTING, '/next-best-test'),
};

export const PATH_RECOMMENDATIONS = {
  root: ROOTS_RECOMMENDATIONS,
  skillsOverride: path(ROOTS_RECOMMENDATIONS, '/skills-override'),
};

export const PATH_COACHING = {
  root: ROOTS_COACHING,
  create: path(ROOTS_COACHING, '/create'),
  edit: path(ROOTS_COACHING, '/edit'),
  view: path(ROOTS_COACHING, '/view'),
};

export const PATH_MANAGEMENT = {
  root: ROOTS_MANAGEMENT,
  studentAppRoster: path(ROOTS_MANAGEMENT, '/student-app-roster'),
  skillPlan: path(ROOTS_MANAGEMENT, '/skill-plan'),
};

const PATH_GUIDE_OVERRIDE = path(ROOTS_GUIDE, '/override');
export const PATH_GUIDE = {
  root: ROOTS_GUIDE,
  testCoaching: {
    root: path(ROOTS_GUIDE, '/test-coaching'),
    create: path(ROOTS_GUIDE, '/test-coaching/create'),
    edit: path(ROOTS_GUIDE, '/test-coaching/edit'),
  },
  override: {
    root: PATH_GUIDE_OVERRIDE,
    create: path(PATH_GUIDE_OVERRIDE, '/create'),
    edit: path(PATH_GUIDE_OVERRIDE, '/edit'),
    view: path(PATH_GUIDE_OVERRIDE, '/view'),
  },
};
