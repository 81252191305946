import { API } from '@aws-amplify/api';
import { AssignmentResponseMetrics } from '../../@types/coaching';

const cache: { [key: string]: { data: AssignmentResponseMetrics | null, timestamp: number } } = {};
const CACHE_DURATION = 24 * 60 * 60 * 1000; // 1 day in milliseconds

export async function getTestDetailsByAssignmentId({
  assignment_id,
  userId,
  isEditView
}: {
    assignment_id: string;
    userId: string;
    isEditView: boolean;
}): Promise<AssignmentResponseMetrics | null> {
  const cacheKey = `${assignment_id}-${isEditView}`;
  const cachedData = cache[cacheKey];

  if (cachedData && (Date.now() - cachedData.timestamp < CACHE_DURATION)) {
    console.log('Cache hit for', cacheKey);
    return cachedData.data;
  }

  try {
    const apiName = process.env.NEXT_PUBLIC_DASH_API_NAME!;
    const path = `coaching/get-assignment-details/${assignment_id}/${userId}`;
    const myInit = {
      queryStringParameters: {
        isEditView: isEditView.toString(),
      },
    };
    const response = await API.get(apiName, path, myInit);
    cache[cacheKey] = { data: response.result, timestamp: Date.now() };
    return response.result;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
