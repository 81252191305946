// form
import { Controller, useFormContext } from 'react-hook-form';
// @mui
import { TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'common/@types/dayjs-custom';
import { formatDayjs } from '@common/utils/formatTime';
import { Dayjs } from 'dayjs';

// ----------------------------------------------------------------------

type IProps = {
  name: string;
  noDefault?: boolean;
};

type Props = IProps & TextFieldProps & { format?: string };

export default function RHFDatePicker({ name, noDefault = false, value, ...other }: Props) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={value}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker 
          openTo="day"
          views={['year', 'month', 'day']}
          label={other.label}
          value={noDefault && !field.value ? undefined : dayjs(field.value)}
          onChange={(newValue: Dayjs | null) => {
            field.onChange(newValue ? formatDayjs(newValue) : null);
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              margin: 'normal',
              helperText: error?.message,
              ...other,
            },
          }}
        />
      )}
    />
  );
}
