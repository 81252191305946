import { capitalCase } from 'change-case';
// next
// @mui
import { styled } from '@mui/material/styles';
import { Box, Container, Stack, Tooltip, Typography } from '@mui/material';
// routes
// hooks
import useAuth from '@common/hooks/useAuth';
// guards
import GuestGuard from '../../guards/GuestGuard';
// components
import Page from '@common/components/Page';
import Logo from '@common/components/Logo';
import Image from '@common/components/Image';
import Iconify from '@common/components/Iconify';
// sections
import { LoginForm } from '../../sections/auth/login';

// Google login bits
import { LoadingButton } from '@mui/lab';
import { useState, useEffect } from 'react';
import Amplify from '@aws-amplify/core';
import { Auth, CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { mixpanelService } from '@common/analytics/mixpanel';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuth();

  const [errorState, setErrorState] = useState<string | null>(null);

  // Hide fully for now until process is set up
  const showGoogleButton = false;

  const startOAuth = async (): Promise<void> => {
    try {
      mixpanelService.track('Login Click', { using: 'App via SSO' });
      // We're leaving the site, so we wanna reconfigure Amplify quickly to use the refresh
      // token flow. We can't configure it for multiple at the same time, and our usual
      // default value is user/pass. We only need these two set for two milliseconds before
      // we leave the site, so that `federatedSignIn` below works as intended.
      Amplify.configure({
        Auth: {
          authenticationFlowType: 'REFRESH_TOKEN_AUTH',
          federationTarget: 'COGNITO_USER_POOLS',
        },
      });

      // Trigger the signin
      Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });

      // Then, once we've set that, _reset_ it back so that if the user hits the back button,
      // everything still works with user/pass auth. It's annoying. I know.
      Amplify.configure({
        Auth: {
          authenticationFlowType: 'USER_PASSWORD_AUTH',
        },
      });
    } catch (error) {
      console.error(error);
      setErrorState(error.message);
    }
  };

  const checkForError = async () => {
    // Check for dev error
    const cognitoDomainURL = process.env.NEXT_PUBLIC_COGNITO_DOMAIN_URL;
    if (!cognitoDomainURL || !cognitoDomainURL.startsWith('https')) {
      console.error(
        'Dash is misconfigured! NEXT_PUBLIC_COGNITO_DOMAIN_URL environment variable was not set before "export" was run.'
      );
      setErrorState(
        'There is an issue with the Dash website. If you know your username and password, you can still sign in below. If you need help, please ask your Guide.'
      );
      return;
    }

    // Check for issues with the login
    let errorMessage;
    const user = await Auth.currentAuthenticatedUser();
    const errorClaim = user.signInUserSession.idToken.payload['custom:error'];
    if (errorClaim) {
      // Specifically "no Dash user found that corresponded to the Google user"
      if (errorClaim === 'no-matching-user') {
        errorMessage =
          'Sorry! Your Alpha email address has not been linked to Dash yet. If you know your username and password, you can sign in below. If you need help, please ask your Guide.';
      } else {
        errorMessage =
          'If you know your username and password, you can sign in below. If you need help, please ask your Guide.';
      }

      setErrorState(errorMessage);
      sessionStorage.setItem('errorState', JSON.stringify(errorMessage));
      await Auth.signOut();
    }
  };

  useEffect(() => {
    const errorStateFromStorage = sessionStorage.getItem('errorState');
    if (errorStateFromStorage) {
      setErrorState(JSON.parse(errorStateFromStorage));
      sessionStorage.removeItem('errorState');
    }
    checkForError();
  }, []);

  return (
    <GuestGuard>
      <Page title="Login">
        <RootStyle>
          <HeaderStyle>
            <Logo />
          </HeaderStyle>

          <Container maxWidth="sm">
            <ContentStyle>
              <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4" gutterBottom>
                    Sign in to Academics App
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    Enter your details below.
                  </Typography>
                </Box>

                <Tooltip title={capitalCase(method)} placement="right">
                  <>
                    <Image
                      disabledEffect
                      alt={method}
                      src={`https://minimal-assets-api-dev.vercel.app/assets/icons/auth/ic_${method}.png`}
                      sx={{ width: 32, height: 32 }}
                    />
                  </>
                </Tooltip>
              </Stack>

              {errorState && (
                <Typography sx={{ fontWeight: 'bold', marginBottom: '24px' }} color="error">
                  {errorState}
                </Typography>
              )}

              <LoginForm />

              {/* Left outside the LoginForm because that's tightly coupled with Yup */}
              {showGoogleButton ? (
                <LoadingButton
                  fullWidth
                  sx={{ marginTop: '12px', textTransform: 'initial' }}
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={
                    false /* could have startOAuth set to true, but causes issues when the user hits the back button */
                  }
                  onClick={startOAuth}
                >
                  <Iconify
                    icon="devicon:google"
                    width={24}
                    height={24}
                    sx={{ marginRight: '12px' }}
                  />
                  Login with Google (@alpha.school)
                </LoadingButton>
              ) : null}
            </ContentStyle>
          </Container>
        </RootStyle>
      </Page>
    </GuestGuard>
  );
}
