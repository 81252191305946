import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Container, Typography } from '@mui/material';
// layouts
import Layout from '@common/layouts';
// components
import Page from '@common/components/Page';
import { MotionContainer, varBounce } from '@common/components/animate';
// assets
import { ForbiddenIllustration } from '@common/assets';
import Link from 'next/link';

// Logout
import useAuth from '@common/hooks/useAuth';

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

Page403.getLayout = function getLayout(page: React.ReactElement) {
  return <Layout variant="logoOnly">{page}</Layout>;
};

// ----------------------------------------------------------------------

export default function Page403() {
  const { logout } = useAuth();

  return (
    <Page title="403 Forbidden">
      <Container component={MotionContainer}>
        <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
          <m.div variants={varBounce().in}>
            <Typography variant="h3" paragraph>
              No permission
            </Typography>
          </m.div>

          <m.div variants={varBounce().in}>
            <Typography sx={{ color: 'text.secondary' }}>
              The page you're trying access has restricted access.
              <br />
              Please refer to your system administrator.
            </Typography>
          </m.div>

          <m.div variants={varBounce().in} style={{ padding: '24px' }}>
            <Link
              href={('https://' + process.env.NEXT_PUBLIC_DASH_FRONTEND_URL) as string}
              passHref
            >
              <Button size="large" variant="contained">
                Go to Dash
              </Button>
            </Link>

            <Button
              size="large"
              variant="contained"
              onClick={logout}
              style={{ marginLeft: '12px', display: 'inline-block' }}
            >
              Logout
            </Button>
          </m.div>

          <m.div variants={varBounce().in}>
            <ForbiddenIllustration sx={{ height: 260, my: { xs: 5, sm: 10 } }} />
          </m.div>
        </ContentStyle>
      </Container>
    </Page>
  );
}
